import { type FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { Like } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./action-like.module.scss"

type Props = {
  className?: string
  liked: boolean
  onLike: (e: React.MouseEvent) => void
}

const ActionLike: FC<Props> = ({ className, liked, onLike }) => {
  const { t } = useI18n()

  return (
    <>
      <div className={className}>
        <Tooltip text={t("tooltip.addToFavorites")}>
          <button className={clsx(liked && styles["disabled"], QA_CLASS_NAMES.photo.favourites)} onClick={onLike}>
            <Like width="18" height="16" className={clsx(liked && styles["liked"])} fill="none" />
          </button>
        </Tooltip>
      </div>
    </>
  )
}
export { ActionLike }
