import { type FC, useState } from "react"
import { CSSTransition } from "react-transition-group"
import clsx from "clsx"

import styles from "./action-bar-layout.module.scss"

type Props = {
  visible: boolean
  isHideActionVisible: boolean
  renderHideAction: (className: string, onMouseEnter: () => void, onMouseLeave: () => void) => React.ReactElement
  renderChildren: (className: string) => React.ReactElement
}

const classNames = {
  enter: styles["enter"],
  enterActive: styles["enter-active"],
  enterDone: styles["enter-done"],
  exit: styles["exit"],
  exitActive: styles["exit-active"],
}

const ActionBarLayout: FC<Props> = ({ visible, isHideActionVisible, renderHideAction, renderChildren }) => {
  const [isHoverActions, setIsHoverActions] = useState(true)

  const handleMouseEnter = () => {
    setIsHoverActions(true)
  }

  const handleMouseLeave = () => {
    setIsHoverActions(false)
  }

  return (
    <CSSTransition
      in={visible}
      timeout={{
        enter: 0,
        exit: 300,
      }}
      classNames={classNames}
      unmountOnExit
    >
      <div className={styles["action-wrap"]}>
        <div className={styles["action-top"]}>
          {isHideActionVisible &&
            renderHideAction(
              clsx(styles["action"], isHoverActions && styles["action-blur"]),
              handleMouseEnter,
              handleMouseLeave
            )}
        </div>
        <div className={styles["action-bar"]}>
          <div
            className={clsx(styles["actions"], isHoverActions && styles["actions-blur"])}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {renderChildren(styles["action"])}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export { ActionBarLayout }
