import type { FC } from "react"

import { QA_CLASS_NAMES } from "@app/constants"
import { PhotoInterface } from "@app/features/gallery/types"
import { HidingMediaFile } from "@app/features/hiding-media-files/components"

type Props = {
  photo: PhotoInterface
  className: string
  onMouseEnter: () => void
  onMouseLeave: () => void
}
const ActionHide: FC<Props> = ({ className, photo, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/* @ts-expect-error NOTE: Компонент на js */}
      <HidingMediaFile mediaFileById={photo} buttonClassName={QA_CLASS_NAMES.photo.hiding} />
    </div>
  )
}
export { ActionHide }
