import create from "zustand"

const usePhotoCarouselStore = create((set) => ({
  isPhotoCarouselVisible: false,
  allPhotos: [],
  startPhoto: null,
  activePhoto: null,
  setAllPhotos: (data) =>
    set(() => {
      return { allPhotos: data }
    }),
  setStartPhoto: (activePhotoIndex, id) =>
    set((state) => {
      if (id) {
        const activeMediaIndex = state.allPhotos.findIndex((item) => item.id === id)
        return {
          startPhoto: {
            index: activeMediaIndex,
            ...state.allPhotos.find((photo, index) => index === activeMediaIndex),
          },
        }
      } else {
        return {
          startPhoto: {
            index: activePhotoIndex,
            ...state.allPhotos.find((photo, index) => index === activePhotoIndex),
          },
        }
      }
    }),
  setActivePhoto: (activePhotoIndex, id) =>
    set((state) => {
      if (id) {
        const activeMediaIndex = state.allPhotos.findIndex((item) => item.id === id)
        return {
          activePhoto: { index: activeMediaIndex, ...state.allPhotos[activeMediaIndex] },
        }
      } else {
        return {
          activePhoto: {
            index: activePhotoIndex,
            ...state.allPhotos.find((_, index) => index === activePhotoIndex),
          },
        }
      }
    }),
  showPhotoCarousel: () => set(() => ({ isPhotoCarouselVisible: true })),
  hidePhotoCarousel: () => set(() => ({ isPhotoCarouselVisible: false })),
  setPhotoLikeStatus: (photoId, likeStatus) =>
    set((state) => {
      return {
        allPhotos: state.allPhotos.map((photo) => {
          if (photo.id === photoId) {
            photo.isLiked = likeStatus
            return photo
          } else {
            return photo
          }
        }),
      }
    }),
  likeActivePhoto: () =>
    set((state) => {
      return {
        activePhoto: {
          ...state.activePhoto,
          isLiked: true,
        },
      }
    }),
}))

export { usePhotoCarouselStore }
