import { memo, useMemo } from "react"
import JustifiedGrid from "react-justified-grid"
import { Image } from "react-justified-grid/dist/interfaces"
import clsx from "clsx"

import { MasonryItem, MasonryProps } from "../masonry/masonry"

import styles from "./horizontal.module.scss"

export type ItemAsImage<T extends MasonryItem> = {
  sourceItem: T
} & Image

const isItemAsImage = <T extends MasonryItem>(item: unknown): item is ItemAsImage<T> =>
  !!(item as ItemAsImage<T>).sourceItem

export type MasonryHorizontalProps<T extends MasonryItem> = {
  maxRowHeight: number
  mapItemToImage: (item: T) => ItemAsImage<T>
} & MasonryProps<T>

const MasonryHorizontalComponent = <T extends MasonryItem>({
  items,
  renderItem,
  mapItemToImage,
  getItemId,
  getItemClassName,
  onReady = () => {},
  maxRowHeight,
}: MasonryHorizontalProps<T>) => {
  const itemsAsImages = useMemo<ItemAsImage<T>[]>(
    () => items.map((file) => mapItemToImage(file)),
    [items, mapItemToImage]
  )

  return (
    <>
      <JustifiedGrid images={itemsAsImages} maxRowHeight={maxRowHeight} showIncompleteRow={true} gutter={0}>
        {(processedImages) => {
          return (
            <>
              {processedImages.map((file, key) => {
                const { width, height, left, top } = file

                if (!isItemAsImage<T>(file.originalData)) return null

                const item = file.originalData.sourceItem

                return (
                  <div
                    key={item.id}
                    id={getItemId(item)}
                    className={clsx(styles["item"], getItemClassName(item))}
                    style={{ width, height, left, top }}
                  >
                    {renderItem(item)}
                  </div>
                )
              })}
            </>
          )
        }}
      </JustifiedGrid>
    </>
  )
}

const MasonryHorizontal = memo(MasonryHorizontalComponent)

export { MasonryHorizontal }
