import { useEffect, useRef, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"

import { useAddFavoritePhoto, useCreateFavoritelist, useDeleteFavoritePhoto } from "@app/api/favorites/mutations"
import { useGetFavoritesList } from "@app/api/favorites/queries"
import { getFavoritesListByIdKey, getFavoritesListKey } from "@app/api/favorites/query-keys"
import { useFavoriteListsStore } from "@app/features/favorites/store"
import { useLikedPhoto } from "@app/features/favorites/store/hooks"
import { ADDED_TO_FAVORITES_NOTIFICATION_TYPE } from "@app/features/notification/constants"
import { useNotificationStore } from "@app/features/notification/store"
import { usePhotoCarouselStore } from "@app/features/photo-carousel/store"
import { useLimitModal } from "@app/features/user/stores"
import { useAuthorization } from "@app/features/user/stores"
import { useAuthorizationModalStore } from "@app/features/user/stores/authorization-modal"
import { checkAccessToken } from "@app/features/user/utils"

// TODO: лучше сделать отдельный хук для лайка в карусели и в превью
export const usePhotoLike = (galleryId: string, liked: boolean) => {
  const isAddedCarouselFavoritePhoto = useRef(false)
  const router = useRouter()
  const queryClient = useQueryClient()
  const { isUserAuthorized } = useAuthorization()
  const addFavoritePhotoMutation = useAddFavoritePhoto()
  const createFavoriteListMutation = useCreateFavoritelist()
  const { data: favoritesList } = useGetFavoritesList(galleryId, { query: { enabled: isUserAuthorized } })
  const showAuthorizationModal = useAuthorizationModalStore((state) => state.showAuthorizationModal)
  const { showLimitModal } = useLimitModal()
  const $showNotification = useNotificationStore((state) => state.showNotification)
  const incrementLikedPhotoCount = useFavoriteListsStore((state) => state.incrementLikedPhotoCount)
  const decrementLikedPhotoCount = useFavoriteListsStore((state) => state.decrementLikedPhotoCount)
  const isPhotoCarouselVisible = usePhotoCarouselStore((state) => state.isPhotoCarouselVisible)
  const setPhotoLikeStatus = usePhotoCarouselStore((state) => state.setPhotoLikeStatus)
  const hidePhotoCarousel = usePhotoCarouselStore((state) => state.hidePhotoCarousel)
  const { $setLikedPhoto } = useLikedPhoto()
  const isFavoritePage = router.pathname.includes("favorites")
  const deleteFavoritePhotoMutation = useDeleteFavoritePhoto({
    mutation: {
      onMutate: async (unlikedPhoto) => {
        if (isFavoritePage) {
          if (isPhotoCarouselVisible) {
            hidePhotoCarousel()
          }

          const slug = router.query.slug
          queryClient.setQueryData(getFavoritesListByIdKey(slug.toString()), (old: any) => {
            return {
              ...old,
              data: {
                ...old.data,
                photos: old.data.photos.filter((photo) => photo.id !== unlikedPhoto.data.photo),
              },
            }
          })
        }
      },
    },
  })

  const [isLiked, setIsLiked] = useState(() => isFavoritePage ?? liked)

  useEffect(() => {
    if (isPhotoCarouselVisible) {
      isAddedCarouselFavoritePhoto.current = false
    }
  }, [isPhotoCarouselVisible])

  useEffect(() => {
    if (isFavoritePage) {
      setIsLiked(true)
    }
  }, [isFavoritePage])

  useEffect(() => {
    if (isFavoritePage && isUserAuthorized) {
      setIsLiked(true)
      return
    }

    if (!isUserAuthorized) {
      setIsLiked(false)
      return
    }

    setIsLiked(liked)
  }, [isUserAuthorized, liked, isFavoritePage])

  useEffect(() => {
    return () => {
      if (isPhotoCarouselVisible && isAddedCarouselFavoritePhoto.current) {
        queryClient.invalidateQueries({ queryKey: getFavoritesListKey(galleryId), exact: true })
      }
    }
  }, [isPhotoCarouselVisible, queryClient, galleryId])

  const showSuccessNotification = (image, id) => {
    $showNotification({
      type: ADDED_TO_FAVORITES_NOTIFICATION_TYPE,
      image,
      link: `/favorites-list/${id}`,
    })
  }

  const handleLike = async (e: React.MouseEvent, photoId: string, imageSrc: string) => {
    e.stopPropagation()
    if (!checkAccessToken()) {
      $setLikedPhoto({
        photoId,
        imageSrc,
      })
      showAuthorizationModal()

      return
    }

    isAddedCarouselFavoritePhoto.current = true

    // Есть избранный список принадлежащей данной галерее
    if (favoritesList.data) {
      incrementLikedPhotoCount()
      showSuccessNotification(imageSrc, favoritesList.data.id)
      if (!favoritesList.data.isFull) {
        setIsLiked(true)
        setPhotoLikeStatus(photoId, true)
        addFavoritePhotoMutation.mutateAsync({
          id: favoritesList.data.id,
          data: { photo: photoId },
        })
      } else {
        showLimitModal()
      }
    }

    // Нет избранных списоков принадлежащих данной галерее
    if (!favoritesList.data) {
      setIsLiked(true)
      incrementLikedPhotoCount()
      setPhotoLikeStatus(photoId, true)
      const createdFavoriteList = await createFavoriteListMutation.mutateAsync({
        data: {
          gallery: galleryId,
          description: "",
          name: "Избранное",
        },
      })

      showSuccessNotification(imageSrc, (createdFavoriteList as any).data.id)

      await addFavoritePhotoMutation.mutateAsync({
        id: (createdFavoriteList as any).data.id,
        data: { photo: photoId },
      })

      queryClient.invalidateQueries({ queryKey: getFavoritesListKey(galleryId), exact: true })
    }
  }

  const handleUnlike = (e: React.MouseEvent, photoId: string) => {
    e.stopPropagation()
    if (checkAccessToken()) {
      setIsLiked(false)
      decrementLikedPhotoCount()
      setPhotoLikeStatus(photoId, false)
      deleteFavoritePhotoMutation.mutateAsync({ id: favoritesList.data.id, data: { photo: photoId } })
    }
  }

  return { isLiked, setIsLiked, handleLike, handleUnlike }
}
