import type { FC } from "react"
import { useI18n } from "next-localization"

import { QA_CLASS_NAMES } from "@app/constants"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { getPhotoDirectURL } from "@app/features/photo/utils"
import { useSharing } from "@app/features/sharing/hooks"
import { Share } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

type Props = {
  className?: string
  photoId: string
  imageSrc: string
  photoName: string
}
const ActionShare: FC<Props> = ({ photoId, photoName, imageSrc, className }) => {
  const { t } = useI18n()
  const share = useSharing()

  const handleShareClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    trackYandexMetrikaAction("click-hover-share-photo-desktop")
    share("photo", {
      title: photoName,
      url: getPhotoDirectURL(photoId),
      fileURL: imageSrc,
    })
  }

  return (
    <div className={className}>
      <Tooltip text={t("common.share")}>
        <button className={QA_CLASS_NAMES.photo.share} onClick={handleShareClick}>
          <Share width="21" height="17" fill="#fff" />
        </button>
      </Tooltip>
    </div>
  )
}
export { ActionShare }
