import type { FC } from "react"
import { useI18n } from "next-localization"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { useDownloadPhoto } from "@app/features/download/hooks"
import { Download } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

type Props = {
  className?: string
  photoId: string
  sizes: number
}
const ActionDownload: FC<Props> = ({ className, photoId, sizes }) => {
  const { t } = useI18n()
  const downloadPhoto = useDownloadPhoto(photoId, sizes)
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    downloadPhoto()
    trackYandexMetrikaAction("click-hover-download-photo-desktop")
  }
  return (
    <div className={className}>
      <Tooltip text={t("tooltip.downloadPhoto")}>
        <button className={QA_CLASS_NAMES.photo.download} onClick={handleClick}>
          <Download width="18" height="18" stroke={COLORS.white} />
        </button>
      </Tooltip>
    </div>
  )
}
export { ActionDownload }
