import { type FC } from "react"

import { PhotoInterface } from "@app/features/gallery/types"
import { usePhotoLike } from "@app/utils/hooks/favorites/use-photo-like"

import { ActionBarLayout } from "./action-bar-layout"
import { ActionDownload } from "./action-download"
import { ActionHide } from "./action-hide"
import { ActionLike } from "./action-like"
import { ActionPrint } from "./action-print"
import { ActionShare } from "./action-share"
import { ActionUnlike } from "./action-unlike"

// TODO вынести из директории масонри

type Props = {
  galleryId: string
  visible: boolean
  sizes: number
  srcImage: string
  isDownloadEnabled?: boolean
  isCanBeHidden?: boolean
  isPrintEnabled?: boolean
  isMobile?: boolean
  isFavoritesSectionActivated: boolean
  photo: PhotoInterface
}

const ActionBar: FC<Props> = ({
  visible,
  sizes,
  srcImage,
  isDownloadEnabled = true,
  isCanBeHidden = true,
  photo,
  galleryId,
  isPrintEnabled,
  isMobile,
  isFavoritesSectionActivated,
}) => {
  const { handleLike, handleUnlike, isLiked } = usePhotoLike(galleryId, photo.isLiked)

  const handleLikeClick = (e: React.MouseEvent) => {
    handleLike(e, photo.id, srcImage)
  }

  const handleUnlikeClick = (e: React.MouseEvent) => {
    handleUnlike(e, photo.id)
  }

  return (
    <>
      {!isMobile && (
        <ActionBarLayout
          visible={visible}
          isHideActionVisible={isCanBeHidden}
          renderHideAction={(className, onMouseEnter, onMouseLeave) => (
            <ActionHide className={className} photo={photo} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
          )}
          renderChildren={(className) => (
            <>
              {isPrintEnabled && <ActionPrint className={className} />}
              {isDownloadEnabled && <ActionDownload className={className} photoId={photo.id} sizes={sizes} />}
              <ActionShare className={className} photoId={photo.id} imageSrc={srcImage} photoName={photo.name} />
              {isFavoritesSectionActivated && (
                <ActionLike className={className} onLike={handleLikeClick} liked={isLiked} />
              )}
            </>
          )}
        />
      )}
      {isFavoritesSectionActivated && <ActionUnlike visible={visible} onClick={handleUnlikeClick} liked={isLiked} />}
    </>
  )
}

export { ActionBar }
