import type { FC } from "react"
import { useI18n } from "next-localization"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"
import { Printer } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

type Props = {
  className?: string
}
const ActionPrint: FC<Props> = ({ className }) => {
  const { t } = useI18n()
  const $showProductsModal = useShowProductsModal()

  return (
    <div className={className}>
      <Tooltip text={t("tooltip.printPhoto")}>
        <button className={QA_CLASS_NAMES.photo.print} onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}>
          <Printer stroke={COLORS.white} />
        </button>
      </Tooltip>
    </div>
  )
}
export { ActionPrint }
