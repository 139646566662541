import { type FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { Like } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./action-unlike.module.scss"

type Props = {
  className?: string
  liked: boolean
  visible: boolean
  onClick: (e: React.MouseEvent) => void
}

const ActionUnlike: FC<Props> = ({ visible, liked, onClick }) => {
  const { t } = useI18n()
  if (!liked) return null

  return (
    <div className={styles["liked-status-wrapper"]}>
      <Tooltip text={t("tooltip.removeFromFavorites")}>
        <button
          className={clsx(
            styles["liked-photo"],
            visible && styles["without-background"],
            QA_CLASS_NAMES.photo.outFavourites
          )}
          onClick={onClick}
        >
          <Like className={styles["like"]} stroke="#fff" fill="#fff" />
        </button>
      </Tooltip>
    </div>
  )
}

export { ActionUnlike }
