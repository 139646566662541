import React from "react"
import { CSSTransition } from "react-transition-group"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { COLORS } from "@app/constants"
import { useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { useDownloadVideo } from "@app/features/download/hooks"
import { HidingMediaFile } from "@app/features/hiding-media-files/components"
import { useGetAllPhotos } from "@app/features/photo-carousel/store/hooks"
import { useSharing } from "@app/features/sharing/hooks"
import { getVideoDirectUrl } from "@app/features/video/utils"
import { Download, Share } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./action-bar-video.module.scss"

function ActionBarVideo({ visible, src, fileKey, id, type, name, isDownloadEnabled, isCanBeHidden, isHiddenScene }) {
  const $allPhotos = useGetAllPhotos()

  const downloadPhoto = useDownloadVideo(id, [fileKey])

  const i18n = useI18n()

  const {
    userAgent: { isMobile },
  } = useGlobalState()

  const share = useSharing()
  function handleShareClick() {
    trackYandexMetrikaAction("click-hover-share-video-desktop")
    share("video", {
      title: name,
      url: getVideoDirectUrl(id),
      fileURL: src,
    })
  }

  const photoById = $allPhotos.find((photo) => photo.id === id)

  function handleDownloadClick() {
    downloadPhoto()
    trackYandexMetrikaAction("click-hover-download-photo-desktop")
  }

  const isVisibleHiddenBtn = isHiddenScene && isCanBeHidden

  const [isHoverActions, setIsHoverActions] = React.useState(false)
  function handleMouseEnter() {
    setIsHoverActions(true)
  }

  function handleMouseLeave() {
    setIsHoverActions(false)
  }

  return (
    <React.Fragment>
      {isVisibleHiddenBtn && (
        <CSSTransition
          in={isHiddenScene}
          timeout={{
            enter: 0,
            exit: 300,
          }}
          classNames={{
            enter: styles["enter"],
            enterActive: styles["enter-active"],
            enterDone: styles["enter-done"],
            exit: styles["exit"],
            exitActive: styles["exit-active"],
          }}
          unmountOnExit
        >
          <div className={styles["action-wrap"]}>
            <div className={styles["action-top"]}>
              {isCanBeHidden && (
                <div
                  className={clsx(styles["action"], styles["visible"])}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <HidingMediaFile
                    type="video"
                    mediaFileById={photoById}
                    buttonClassName={styles["button"]}
                    isMobile={isHiddenScene}
                  ></HidingMediaFile>
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      )}

      {!isMobile && (
        <CSSTransition
          in={visible}
          timeout={{
            enter: 0,
            exit: 300,
          }}
          classNames={{
            enter: styles["enter"],
            enterActive: styles["enter-active"],
            enterDone: styles["enter-done"],
            exit: styles["exit"],
            exitActive: styles["exit-active"],
          }}
          unmountOnExit
        >
          <div className={styles["action-wrap"]}>
            <div className={styles["action-top"]}>
              {isCanBeHidden && !isVisibleHiddenBtn && (
                <div
                  className={clsx(styles["action"], { [styles["action-blur"]]: isHoverActions })}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <HidingMediaFile
                    type="video"
                    mediaFileById={photoById}
                    buttonClassName={styles["button"]}
                  ></HidingMediaFile>
                </div>
              )}
            </div>
            <div className={styles["action-bar"]}>
              <div
                className={clsx(styles["actions"], { [styles["actions-blur"]]: isHoverActions })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {isDownloadEnabled && (
                  <div className={styles["action"]}>
                    <Tooltip text={i18n.t("tooltip.downloadVideo")}>
                      <button className={styles["button"]} onClick={handleDownloadClick}>
                        <Download width="18" height="18" stroke={COLORS.white}></Download>
                      </button>
                    </Tooltip>
                  </div>
                )}
                <div className={styles["action"]}>
                  <Tooltip text={i18n.t("common.share")}>
                    <button className={clsx(styles["button"], styles["share-button"])} onClick={handleShareClick}>
                      <Share width="21" height="17" stroke={COLORS.white} fill={COLORS.white}></Share>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </React.Fragment>
  )
}

export { ActionBarVideo }
