import { usePhotoCarouselStore } from "features/photo-carousel/store"

const visibilitySelector = (state) => state.isPhotoCarouselVisible
const showPhotoCarouselSelector = (state) => state.showPhotoCarousel
const hidePhotoCarouselSelector = (state) => state.hidePhotoCarousel
const startPhotoSelector = (state) => state.startPhoto
const setStartPhotoSelector = (state) => state.setStartPhoto
const activePhotoSelector = (state) => state.activePhoto
const setActivePhotoSelector = (state) => state.setActivePhoto

function usePhotoCarousel() {
  const $isPhotoCarouselVisible = usePhotoCarouselStore(visibilitySelector)
  const $showPhotoCarousel = usePhotoCarouselStore(showPhotoCarouselSelector)
  const $hidePhotoCarousel = usePhotoCarouselStore(hidePhotoCarouselSelector)
  const $startPhoto = usePhotoCarouselStore(startPhotoSelector)
  const $setStartPhoto = usePhotoCarouselStore(setStartPhotoSelector)
  const $activePhoto = usePhotoCarouselStore(activePhotoSelector)
  const $setActivePhoto = usePhotoCarouselStore(setActivePhotoSelector)

  return {
    $isPhotoCarouselVisible,
    $showPhotoCarousel,
    $hidePhotoCarousel,
    $startPhoto,
    $setStartPhoto,
    $activePhoto,
    $setActivePhoto,
  }
}

export { usePhotoCarousel }
